<template>
  <v-container>
    <v-row class="text-h5">
      <v-col cols="10"> Products </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-data-table
          :items="tableData"
          :headers="headers"
          :loading="loading"
          :loading-text="loadingText"
          :no-data-text="noDataText"
        >
          <template v-slot:item.Hours="{ item }">
            <v-text-field
              v-model="item.Hours"
              type="Number"
              @input="updateHours(item)"
            />
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import strapiApi from "../mixins/strapiApi";
export default {
  name: "ProductTable",
  components: {
  },
  mixins: [strapiApi],
  data: () => ({
    loading: true,
    loadingText: "",
    noDataText: "",
    products: [],
    workDays: [],
    headers: [
      { text: "Date", value: "Date" },
      { text: "Hours", value: "Hours" },
    ],
    packages: [],
    order: null,
    detailDialog: false,
    expanded: [],
    syncingItem: false,
  }),
  async mounted() {
    try {
      this.loadingText = "Syncing Strapi and Shopify product records";
      await this.syncEndpoint("products");
      this.loadingText = "Getting strapi products";
      this.workDays = await this.getWorkDays("");
      this.loading = false;
    } catch (error) {
      this.noDataText = "Error loading products. Is Strapi service running?";
      this.loading = false;
      console.error(error);
    }
  },
  methods: {
    updateHours(item) {
      console.log(item)
      this.updateWorkHours(item.id, { Hours: item.Hours });
    },
  },
  computed: {
    tableData() {
      return this.workDays
    },
  },
};
</script>
<style>
.product-detail-td {
  border-color: rgba(0, 0, 0, 0.12);
  border-style: solid;
  border-top-style: none;
  border-width: thin;
}
.v-data-table__expanded__content {
  box-shadow: none !important;
  background-color: #f5f5f5;
}
</style>
